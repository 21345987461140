<template>
  <div class="order-detail">
    <nav-bar :bgc="'background:#fff'" :isFixed="true" :title="'下單'"></nav-bar>
    <div :class="['order-content',{ headerTop: !isWeiXin() }]">
      <div class="adderss" @click="handleAddress" v-if="orderData.addresses?.length === 0">
        <img src="@/assets/icons/general@2x.png" alt="" />
        {{ $t("添加地址") }}
      </div>
      <div class="adderss" v-else>
        <div class="address-detail" @click="handleAddress">
          <div class="address-content">
            <div class="top">
              <div class="address-name">{{ defaultAddresses.name }}</div>
              <div class="address-phone">{{ defaultAddresses.phone }}</div>
            </div>
            <div>
              <div class="address-street">
                {{ defaultAddresses.address }}
              </div>
            </div>
            <div class="address-city">
              {{ spliceAddress(defaultAddresses.province) }}
              {{ spliceAddress(defaultAddresses.city) }}
              {{ spliceAddress(defaultAddresses.district) }}
              {{ defaultAddresses.street }}
            </div>
          </div>
          <img class="arrow" src="@/assets/icons/arrow-right.png" alt="" />
        </div>
      </div>
      <div class="pay-type">
        <div class="pay-title">{{ $t("付款方式") }}</div>
        <div class="phone-list">
          <div v-for="item in columns" :key="item.value" @click="handleAreaCode(item)">
            <div :class="['phone-item',{ actvie: item.value == selectPhone }]">
              <div class="phone-text">{{ item.label }}</div>
              <img class="phone-check" v-if="item.value == selectPhone" src="@/assets/icons/check.png" alt="" />
            </div>
            <div v-if="selectPhone == item.value && selectPhone !== '0'" class="phone-tip">
              <div class="phone-title">{{ item.tip }}</div>
              <div class="richText" v-html="item.text"></div>
              <div class="copy-writing" @click="copyInformation(item.text)">
                <img src="@/assets/icons/copy.png" alt="" class="copywritingimg" />
                {{ $t("複製賬戶信息") }}
              </div>
            </div>
          </div>
          <!-- <div v-if="!orderData.canUseCheck">
            <div class="phone-item">
              <div :class="[{ 'disable-text': !orderData.canUseCheck }]">{{ orderData.paymentSet.chequeName }}</div>
            </div>
            <div class="tigs-red">{{ $t("您有一張未結清的支票訂單，轉帳後可以繼續使用支票付款") }}</div>
          </div> -->
        </div>
      </div>
      <div class="Stash">
        <div class="goods-list" v-for="(item,index) in orderData.splitOrders" :key="index">
          <div class="Stash-title">
            {{ item.storeName }}
          </div>
          <div class="goods-item" v-for="(goods,index) in item.orderTemps.slice(0,orderIndex)" :key="index">
            <div>
              <img :src="getProductImageUrl(goods.picturePath,400)" alt="" />
            </div>
            <div class="goods-info">
              <div class="goods-name">
                {{ goods.name }}
              </div>
              <div class="goods-unit">{{ goods.standard }}</div>
              <div class="goods-price">
                <div class="price">{{ formatNumber(goods.unitPrice) }}</div>
                <div class="count">{{ $t("數量") }}{{ goods.amount }}</div>
              </div>
            </div>
          </div>
          <div class="viewall" @click="getViewallGoods('1')" v-if="item.orderTemps.length > 3 && unfold">
            {{ $t("查看所有") }} {{ item.orderTemps.length }}
            {{ $t("個商品") }}
            <img src="@/assets/icons/direction@2x.png" alt="" />
          </div>
          <div class="viewall" @click="getViewallGoods('2')" v-if="!unfold">
            {{ $t("收起") }}
            <img src="@/assets/icons/unfold.png" alt="" />
          </div>
          <div v-for="(distribution,index) in item.expressPrice" :key="index">
            <div class="Distribution-mode" v-if="distribution.isDefault">
              <div class="Distribution-item">
                <div class="label">{{ $t("配送方式") }}</div>
                <div>{{ distribution.expressName }}</div>
              </div>
              <div class="Distribution-item">
                <div class="label">{{ $t("運費") }}</div>
                <div class="freight">
                  {{ formatNumber(distribution.freight) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="leaveMessage">
        <div class="leaveMessage-title">{{ $t("給商家留言") }}</div>
        <div class="leaveMessage-inp">
          <input v-model="remark" type="text" :placeholder="$t('請填寫留言内容')" />
        </div>
        <div class="aggregate">
          {{ $t("給商家留言") }}{{ $t("訂單總計 ") }}({{ length
          }}{{ $t("件商品") }})
        </div>
        <div class="aggregate-price">
          <div>{{ $t("產品價格") }}</div>
          <div>{{ formatNumber(goodsTotal) }}</div>
        </div>
        <div class="aggregate-price">
          <div>{{ $t("運費總計") }}</div>
          <div>
            {{ formatNumber(freightTotal) }}
          </div>
        </div>
        <div class="aggregate-price">
          <div>{{ $t("優惠") }}</div>
          <div class="discountsprice">
            -{{ formatNumber(Math.abs(preferential)) }}
          </div>
        </div>
        <div class="aggregate-price">
          <div>{{ $t("需支付总额") }}</div>
          <div class="discountsprice">
            {{ formatNumber(orderData.totalPrice + freightTotal) }}
          </div>
        </div>
      </div>
      <div class="order-footer">
        <div class="price">{{ formatNumber(orderData.totalPrice) }}</div>
        <div class="Placeorder" @click="getCreateOrder">
          {{ $t("下單") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getProductOrderDetail } from "@/api/productdetails.js"
import mixins from "@/utils/mixins.js"
import { mapState } from "vuex"
import { getCreateOrder2, pollOrderStatus } from "@/api/user.js"
export default {
  mixins: [mixins],
  data () {
    return {
      columns: [],
      orderData: {},
      selectPhone: "0",
      orderIndex: 3,
      freightTotal: 0,
      goodsTotal: 0,
      preferential: 0,
      length: 0,
      unfold: true,
      defaultAddresses: {},
      remark: ""
    }
  },
  computed: {
    ...mapState("user", ["checkAddress"])
  },
  async created () {
    await this.getOrderDetail()
    this.getFreightTotal()
    if (this.checkAddress?.id) {
      this.defaultAddresses = this.checkAddress
    } else {
      this.defaultAddresses = this.orderData.addresses.find(
        item => item.isDefault
      )
    }
    console.log(this.freightTotal)
    console.log(this.preferential)
  },
  methods: {
    async getCreateOrder () {
      const toast1 = this.$toast({
        message: this.$t("正在查詢訂單狀態"),
        duration: 0,
        forbidClick: true
      })
      let storeExpress = this.orderData.splitOrders?.map((item, index) => {
        let express = item.expressPrice.find(i => i.isDefault)
        return {
          storeId: item.storeId,
          expressId: express && express.expressId
        }
      })
      let obj = {
        isFromShoppingCart: false, //是否购物车
        storeExpress: storeExpress,
        remark: this.remark,
        isRemittance: this.selectPhone == "1"
      }
      const res = await getCreateOrder2(obj)
      if (res.resultID === 1200) {
        const resData = await pollOrderStatus({ id: res.data })

        if (
          resData.resultID === 1200 &&
          resData.data &&
          resData.data.isSuccess
        ) {
          toast1.close()
          if (this.selectPhone == "0") {
            this.$router.push(`/pay/${res.data}`)
          } else if (this.selectPhone == "1") {
            this.$router.push(
              `/payResult?type=success&payType=52&orderId=${res.data
              }&price=${this.orderData.totalPrice + this.freightTotal}&html=${this.orderData.paymentSet.pubTransferAccount
              }&tips=${this.orderData.paymentSet.pubTransferCopywriting}`
            )
          }
        } else {
          this.$toast(resData.data.message || resData.message)
        }
      } else {
        toast1.close()
        this.$toast(res.message)
      }
    },
    spliceAddress (str) {
      const regex = new RegExp(`(?:${"●"})(.*)`)
      const match = (str || "").match(regex)
      return match && match[1] ? match[1] + "," : ""
    },
    handleAddress () {
      this.$router.push(`/user/address?type=1&id=${this.defaultAddresses.id}`)
      this.$store.dispatch("user/getEditAddress", this.defaultAddresses.id)
    },
    copyInformation (item) {
      let textToCopy = ""
      const paragraphs = document.querySelectorAll(".richText p")

      // 遍历每个 p 元素
      for (let i = 0; i < paragraphs.length; i++) {
        const paragraph = paragraphs[i]
        // 获取 p 元素内的文本内容
        const textContent = paragraph.textContent.trim()
        // 将文本内容添加到要复制的字符串中
        textToCopy += textContent + "\n" // 添加换行符
      }
      var tempInput = document.createElement("textarea")
      // 设置输入框的值为要复制的文本
      tempInput.value = textToCopy
      // 将输入框添加到页面中
      document.body.appendChild(tempInput)
      // 选中输入框中的文本
      tempInput.select()
      // 执行复制命令
      document.execCommand("copy")
      // 移除临时输入框
      document.body.removeChild(tempInput)
      this.$toast(this.$t("複製成功!"))
    },
    getFreightTotal () {
      this.orderData.splitOrders.forEach(item => {
        this.goodsTotal += item.totalPrice
        item.orderTemps.forEach(v => {
          this.length += v.amount
        })

        item.expressPrice.forEach(i => {
          console.log(i)

          if (i.isDefault && item.totalPrice < i.freePrice) {
            this.freightTotal += i.freight
          }
        })
      })
      this.preferential = this.orderData.cutList.reduce(
        (prve, next) => prve + next.cutPrice,
        0
      )
    },
    getViewallGoods (type) {
      if (type === "1") {
        this.orderIndex = undefined
        this.unfold = false
      } else {
        this.orderIndex = 3
        this.unfold = true
      }
    },
    handleAreaCode (item) {
      this.selectPhone = item.value
    },
    async getOrderDetail () {
      const res = await getProductOrderDetail()
      if (res.resultID === 1200) {
        this.orderData = res.data
        this.columns.push({
          label: this.orderData.paymentSet.onlineName,
          value: "0",
          text: ""
        })
        this.columns.push({
          label: this.orderData.paymentSet.pubTransferName,
          value: "1",
          tip: this.orderData.paymentSet.pubTransferCopywriting,
          text: this.orderData.paymentSet.pubTransferAccount
        })
        // if (res.data?.canUseCheck) {
        //   this.columns.push({
        //     label: this.orderData.paymentSet.chequeName,
        //     value: "2",
        //     tip: this.orderData.paymentSet.chequeCopywriting,
        //     text: this.orderData.paymentSet.chequeAddress
        //   })
        // }

      }
    }
  }
}
</script>

<style lang="scss" scoped>
.order-content {
  padding-bottom: 80px;
}

.adderss {
  color: #3491fa;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 26px;
  background-color: #fff;
  border-radius: 0px 0px 16px 16px;
  margin-bottom: 12px;

  img {
    width: 20px;
  }

  .address-detail {
    width: 100%;
    padding: 12px;
    background: #f8f8f8;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .address-street {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      color: #1a1a1a;
    }

    .address-city {
      margin-top: 2px;
      font-weight: 500;
      font-size: 13px;
      color: #666666;
    }

    .arrow {
      height: 16px;
      width: 16px;
      margin-left: 11px;
    }

    .top {
      display: flex;
      align-items: center;

      font-weight: 500;
      font-size: 16px;
      color: #1a1a1a;
      margin-bottom: 2px;

      .address-name {
        margin-right: 12px;
      }
    }
  }
}

.pay-type {
  padding: 16px;
  background-color: #fff;
  padding-bottom: 0px;
  border-radius: 16px;
  margin-bottom: 12px;

  .pay-title {
    font-weight: 500;
    font-size: 16px;
    color: #1a1a1a;
    line-height: 24px;
  }

  .phone-list {
    padding: 16px;

    .actvie {
      border: 1px solid #90d12e !important;
    }

    .phone-tip {
      background: #ffffff;
      border-radius: 12px 12px 12px 12px;
      border: 1px solid #e4e6e7;
      padding: 16px;
      margin-bottom: 8px;
    }

    .copywritingimg {
      margin-right: 2px;
    }

    .copy-writing {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 8px;
      background: #f6fbee;
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #d2edab;
      color: #90d12e;
      margin-top: 12px;

      img {
        width: 16px;
        height: 16px;
      }
    }

    .phone-title {
      color: #f82207;
      font-size: 12px;
      margin-bottom: 12px;
    }

    .phone-item {
      padding: 12px 16px;
      position: relative;
      background: #f8f8f8;
      border-radius: 8px 8px 8px 8px;
      margin-bottom: 12px;
      border: 1px solid transparent;
      margin-right: 12px;

      .disable-text {
        font-size: 16px;
        color: #999999;
        line-height: 16px;
        text-align: center;
        word-break: break-word;
        max-width: 100%;
      }

      .phone-text {
        font-size: 16px;
        color: #1a1a1a;
        line-height: 16px;
        text-align: center;
        word-break: break-word;
        max-width: 100%;
      }
      

      .phone-check {
        position: absolute;
        width: 12px;
        bottom: -1px;
        right: -1px;
      }
    }
    .tigs-red{
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #F82207;
        line-height: 22px;
        text-align: left;
      }
  }
}

.Stash {
  background-color: #fff;
  border-radius: 16px 16px 0 0;
  padding: 16px;
  padding-top: 0;

  .Stash-title {
    font-weight: 500;
    font-size: 16px;
    color: #1a1a1a;
    padding-top: 16px;
  }

  .goods-item {
    margin-top: 16px;
    display: flex;

    img {
      width: 120px;
      height: 120px;
    }

    .goods-info {
      margin-left: 16px;
      position: relative;

      .goods-name {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        width: 207px;
        font-size: 13px;
        color: #1a1a1a;
      }

      .goods-unit {
        font-size: 12px;
        color: #999999;
        margin-top: 4px;
      }

      .goods-price {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        bottom: 0;
        width: 100%;

        .price {
          font-weight: 800;
          font-size: 16px;
          color: #f82207;
        }
      }
    }
  }

  .viewall {
    font-weight: 400;
    font-size: 15px;
    color: #3491fa;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    img {
      width: 16px;
      height: 16px;
      margin-left: 4px;
    }
  }

  .Distribution-mode {
    border-bottom: 1px solid #f8f8f8;
    padding-bottom: 16px;

    .Distribution-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
    }

    .label {
      font-size: 15px;
      color: #666666;
    }

    .freight {
      font-weight: 500;
      font-size: 15px;
      color: #f82207;
    }
  }
}

.leaveMessage {
  background-color: #fff;
  padding: 0 16px;
  padding-bottom: 6px;

  .leaveMessage-title {
    margin-bottom: 16px;
  }

  .leaveMessage-inp {
    padding-bottom: 16px;
    border-bottom: 1px solid #f8f8f8;

    input {
      width: 100%;
      background-color: #f8f8f8;
      border-radius: 6px;
      height: 40px;
      padding-left: 12px;
    }
  }

  .aggregate {
    margin: 16px 0;
  }

  .aggregate-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .discountsprice {
      font-weight: 500;
      font-size: 15px;
      color: #f82207;
    }
  }
}

.order-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  border-radius: 0px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;

  .price {
    font-weight: 800;
    font-size: 20px;
    color: #f82207;
  }

  .Placeorder {
    padding: 10px 45px;
    background: #90d12e;
    border-radius: 8px 8px 8px 8px;
    color: #fff;
  }
}
</style>
